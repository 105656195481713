import { render, staticRenderFns } from "./TransferRuleCriteria.vue?vue&type=template&id=16799563&scoped=true"
import script from "./TransferRuleCriteria.vue?vue&type=script&lang=js"
export * from "./TransferRuleCriteria.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16799563",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/src/AgendasLtd.TravioPro.Vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16799563')) {
      api.createRecord('16799563', component.options)
    } else {
      api.reload('16799563', component.options)
    }
    module.hot.accept("./TransferRuleCriteria.vue?vue&type=template&id=16799563&scoped=true", function () {
      api.rerender('16799563', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/pricing-rule-manager/sub-criterias/TransferRuleCriteria.vue"
export default component.exports