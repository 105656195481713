var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { attrs: { "no-shadow": "", "card-border": "" } }, [
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full align-center my-2" }, [
            _c("span", [_vm._v("Flight Rule Criteria:")]),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Suppliers:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.flightSupplier,
                  },
                  model: {
                    value: _vm.flightLocalForm.flightRuleCriteria.suppliers,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.flightLocalForm.flightRuleCriteria,
                        "suppliers",
                        $$v
                      )
                    },
                    expression: "flightLocalForm.flightRuleCriteria.suppliers",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Suppliers Codes:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.accomSupplierCode,
                  },
                  model: {
                    value: _vm.flightLocalForm.flightRuleCriteria.supplierCodes,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.flightLocalForm.flightRuleCriteria,
                        "supplierCodes",
                        $$v
                      )
                    },
                    expression:
                      "flightLocalForm.flightRuleCriteria.supplierCodes",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-start" },
                [
                  _c("div", { staticClass: "mr-2" }, [
                    _vm._v("Airline Carrier Codes:"),
                  ]),
                  _c(
                    "vs-checkbox",
                    {
                      attrs: {
                        "vs-value": true,
                        title: _vm.Airline,
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value:
                          _vm.flightLocalForm.flightRuleCriteria
                            .excludeCarriers,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.flightLocalForm.flightRuleCriteria,
                            "excludeCarriers",
                            $$v
                          )
                        },
                        expression:
                          "flightLocalForm.flightRuleCriteria.excludeCarriers",
                      },
                    },
                    [_vm._v("Exclude Selected Carriers")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        rules: _vm.flightLocalForm.productTypes.includes(
                          "Flights"
                        )
                          ? { required: false, validCarrierCode: true }
                          : {},
                      },
                      expression:
                        "{\n              rules: flightLocalForm.productTypes.includes('Flights') ? { required: false, validCarrierCode: true } : {},\n            }",
                    },
                  ],
                  attrs: {
                    multiple: "",
                    name: "airlineCode",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.carrierCode,
                  },
                  model: {
                    value: _vm.flightLocalForm.flightRuleCriteria.carrierCodes,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.flightLocalForm.flightRuleCriteria,
                        "carrierCodes",
                        $$v
                      )
                    },
                    expression:
                      "flightLocalForm.flightRuleCriteria.carrierCodes",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("airlineCode"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Cabin Classes:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.flightLocalHelper.enums.cabinClasses,
                  },
                  model: {
                    value: _vm.flightLocalForm.flightRuleCriteria.cabinClasses,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.flightLocalForm.flightRuleCriteria,
                        "cabinClasses",
                        $$v
                      )
                    },
                    expression:
                      "flightLocalForm.flightRuleCriteria.cabinClasses",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Fare Types:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.flightLocalHelper.enums.fareTypes,
                  },
                  model: {
                    value: _vm.flightLocalForm.flightRuleCriteria.fareTypes,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.flightLocalForm.flightRuleCriteria,
                        "fareTypes",
                        $$v
                      )
                    },
                    expression: "flightLocalForm.flightRuleCriteria.fareTypes",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "vx-row mb-6" },
          [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Flight Route:")]),
            ]),
            _vm._l(
              _vm.flightLocalForm.flightRuleCriteria.routes,
              function (route, routeIndex) {
                return [
                  _c(
                    "div",
                    {
                      key: routeIndex + "0",
                      staticClass: "vx-col w-full md:w-2/5",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("Origin"),
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    required: true,
                                    flightRouteCode: true,
                                  },
                                },
                                expression:
                                  "{\n                rules: { required: true, flightRouteCode: true },\n              }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "origin_" + routeIndex,
                              options:
                                routeIndex === _vm.currentActiveRouteIndex
                                  ? _vm.routeOriginOptions
                                  : _vm.priceRulesFormData.routeCode,
                              reduce: (option) => option.code,
                              selectable: (option) =>
                                !(
                                  option.code ===
                                  _vm.flightLocalForm.flightRuleCriteria.routes[
                                    routeIndex
                                  ].destination
                                ),
                              disabled: _vm.readOnly,
                            },
                            on: {
                              search: _vm.onSearch,
                              "search:focus": function ($event) {
                                return _vm.activeIndex(routeIndex, "origin")
                              },
                            },
                            model: {
                              value:
                                _vm.flightLocalForm.flightRuleCriteria.routes[
                                  routeIndex
                                ].origin,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.flightLocalForm.flightRuleCriteria.routes[
                                    routeIndex
                                  ],
                                  "origin",
                                  $$v
                                )
                              },
                              expression:
                                "\n                flightLocalForm.flightRuleCriteria.routes[routeIndex].origin\n              ",
                            },
                          }),
                          _vm.errors.has("origin_" + routeIndex)
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first("origin_" + routeIndex)
                                        .replace(
                                          "The origin_" + routeIndex,
                                          "Origin route "
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: routeIndex + "1",
                      staticClass: "vx-col w-full md:w-2/5",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("Destination"),
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    required: true,
                                    flightRouteCode: true,
                                  },
                                },
                                expression:
                                  "{\n                rules: { required: true, flightRouteCode: true },\n              }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "flightDestination_" + routeIndex,
                              options:
                                routeIndex === _vm.currentActiveRouteIndex
                                  ? _vm.routeDestOptions
                                  : _vm.priceRulesFormData.routeCode,
                              reduce: (option) => option.code,
                              selectable: (option) =>
                                !(
                                  option.code ===
                                  _vm.flightLocalForm.flightRuleCriteria.routes[
                                    routeIndex
                                  ].origin
                                ),
                              disabled: _vm.readOnly,
                            },
                            on: {
                              search: _vm.onSearch,
                              "search:focus": function ($event) {
                                return _vm.activeIndex(routeIndex, "dest")
                              },
                            },
                            model: {
                              value:
                                _vm.flightLocalForm.flightRuleCriteria.routes[
                                  routeIndex
                                ].destination,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.flightLocalForm.flightRuleCriteria.routes[
                                    routeIndex
                                  ],
                                  "destination",
                                  $$v
                                )
                              },
                              expression:
                                "\n                flightLocalForm.flightRuleCriteria.routes[routeIndex]\n                  .destination\n              ",
                            },
                          }),
                          _vm.errors.has("flightDestination_" + routeIndex)
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first(
                                          "flightDestination_" + routeIndex
                                        )
                                        .replace(
                                          "The flightDestination_" + routeIndex,
                                          "Destination route "
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: routeIndex + "2",
                      staticClass: "vx-col w-full md:w-1/5",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-8",
                              class: _vm.readOnly
                                ? "opacity-50 cursor-not-allowed"
                                : "",
                              attrs: {
                                size: "small",
                                type: "border",
                                color: "danger",
                                disabled: _vm.readOnly,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeRouteFields(routeIndex)
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              }
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full my-3" },
              [
                _c(
                  "vs-button",
                  {
                    class: _vm.readOnly ? "opacity-50 cursor-not-allowed" : "",
                    attrs: {
                      size: "small",
                      type: "border",
                      color: "success",
                      disabled: _vm.readOnly,
                    },
                    on: { click: _vm.addRouteFields },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "vx-row mb-6" },
          [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Fare Bases:")]),
            ]),
            _vm._l(
              _vm.flightLocalForm.flightRuleCriteria.fareBases,
              function (fareBase, fareIndex) {
                return [
                  _c(
                    "div",
                    {
                      key: fareIndex + "0",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("MatchType"),
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { rules: { required: true } },
                                expression: "{ rules: { required: true} }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "matchType_" + fareIndex,
                              options: _vm.flightLocalHelper.enums.matchTypes,
                              reduce: (option) => option.code,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.flightLocalForm.flightRuleCriteria
                                  .fareBases[fareIndex].matchType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.flightLocalForm.flightRuleCriteria
                                    .fareBases[fareIndex],
                                  "matchType",
                                  $$v
                                )
                              },
                              expression:
                                "\n                flightLocalForm.flightRuleCriteria.fareBases[fareIndex]\n                  .matchType\n              ",
                            },
                          }),
                          _vm.errors.has("matchType_" + fareIndex)
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first("matchType_" + fareIndex)
                                        .replace(
                                          "matchType_" + fareIndex,
                                          "Match type"
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: fareIndex + "1",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("Value"),
                          ]),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { rules: { required: true, max: 10 } },
                                expression:
                                  "{ rules: { required: true, max: 10 } }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "fareValue_" + fareIndex,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.flightLocalForm.flightRuleCriteria
                                  .fareBases[fareIndex].value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.flightLocalForm.flightRuleCriteria
                                    .fareBases[fareIndex],
                                  "value",
                                  $$v
                                )
                              },
                              expression:
                                "\n                flightLocalForm.flightRuleCriteria.fareBases[fareIndex].value\n              ",
                            },
                          }),
                          _vm.errors.has("fareValue_" + fareIndex)
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first("fareValue_" + fareIndex)
                                        .replace(
                                          "fareValue_" + fareIndex,
                                          "value"
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: fareIndex + "2",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-8",
                              class: _vm.readOnly
                                ? "opacity-50 cursor-not-allowed"
                                : "",
                              attrs: {
                                size: "small",
                                type: "border",
                                color: "danger",
                                disabled: _vm.readOnly,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFlightDynamicFields(
                                    fareIndex,
                                    "fareBase"
                                  )
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              }
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full my-3" },
              [
                _c(
                  "vs-button",
                  {
                    class: _vm.readOnly ? "opacity-50 cursor-not-allowed" : "",
                    attrs: {
                      size: "small",
                      type: "border",
                      color: "success",
                      disabled: _vm.readOnly,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addFlightDynamicFields("fareBase")
                      },
                    },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }